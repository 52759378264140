<template>
  <template v-if="errorPage">
    <main>
      <router-view :key="route.name"></router-view>
    </main>
  </template>

  <template v-else>
    <app-nav></app-nav>
    <main :id="route.name" class="container">
      <h1 v-if="seoTitle" class="seo-title">{{ seoTitle }}</h1>
      <component v-if="layoutComponent" :is="layoutComponent">
        <router-view :key="uniqueKey"></router-view>
      </component>
      <router-view v-else :key="uniqueKey"></router-view>
    </main>
    <app-footer></app-footer>
  </template>
  
</template>

<script>
import { defineAsyncComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useApplicationStore } from '/stores/application';
import AppNav from '/components/shared/AppNav.vue'
import AppFooter from '/components/shared/AppFooter.vue'

export default {
  components: {
    AppNav,
    AppFooter,
    ListingDetailLayout: defineAsyncComponent(() => import('/components/layouts/ListingDetailLayout.vue')),
  },

  setup() {
    const route = useRoute();
    const store = useApplicationStore();
    const uniqueKey = computed(() => route.params.slug !== 'undefined' ? `${route.name}${route.params.slug}` : route.name);
    const errorPage = computed(() => {
      return route.name === 'error'
    });

    // load child layout if provided
    const layoutComponent = computed(() => {
      const layoutName = route.meta.layout;
      return layoutName ? layoutName : false;
    });

    const seoTitle = computed(() => {
      const seoTitles = [
        { route: 'home', title: 'Arbor Green Development' },
        { route: 'properties-for-sale', title: 'Properties for Sale in Selwyn District' },
        { route: 'sections-for-sale', title: 'Sections for Sale in Selwyn District' },
        { route: 'homes-for-sale', title: 'Homes For Sale in Selwyn District' },
        { route: 'neighbourhood', title: 'A place to connect and belong' },
        { route: 'life-in-rolleston', title: 'Life in Rolleston' },
        { route: 'life-in-arbor-green', title: 'Life in Arbor Green' },
        { route: 'journal', title: 'Arbor Green News' },
        { route: 'documents', title: 'Document Portal' },
        { route: 'faqs', title: 'Frequently Asked Questions' },
        { route: 'about', title: 'About Hughes Developments' },
        { route: 'enquire', title: 'Contact us' },
      ]

      const result = seoTitles.find(title => title.route === route.name);
      return result ? result.title : false;
    });

    return {
      route,
      errorPage,
      layoutComponent,
      uniqueKey,
      seoTitle,
    };
  }
}
</script>