<template>
  <picture>
    <source :sizes="size" :srcset="avifSrcset" type="image/avif">
    <img ref="img"
         :src="fallbackSrc"
         :alt="alt"
         :style="styles"
         :class="{ loaded: loaded}"
         :loading="lazy ? 'lazy' : 'eager'"
         @load="onImageLoad">
  </picture>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useApplicationStore } from '/stores/application'

export default {
  props: {
    src: {
      type: Object,
      required: true
    },
    alt: String,
    position: String,
    lazy: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useApplicationStore()
    const size = ref(null);
    const img = ref(null);
    const loaded = ref(false);
    const bgSrc = ref('');
    const fallbackSrc = ref('');
    const avifSrcset = ref('');
    const serverImageTarget = props.src.url.replace('/resources/', '').replace(/\.[^/.]+$/, "")
    const manifesto = store.serverImageManifesto[serverImageTarget] !== undefined ? store.serverImageManifesto[serverImageTarget] : null;
    const serverImgPrefix = store.fullURL + '/public/server-generated/'
    const curImage = computed(() => {
      return manifesto !== null ? serverImgPrefix + manifesto.fallback : props.src.url

    });

    const getSize = () => {
      const image = img.value;
      
      const width = Math.floor(image.offsetWidth);
      const height = Math.floor(image.offsetHeight);
      const ratio = width / height;

      const originalWidth = props.src.width;
      const originalHeight = props.src.height;
      const imgRatio = originalWidth / originalHeight;

      const overflow = ratio > imgRatio ? 'y' : 'x';
      const proposedWidth = Math.round(height * imgRatio);

      if (overflow === 'y') {
        size.value = width > proposedWidth ? `${width}px` : `${proposedWidth}px`;
      } else {
        size.value = `${proposedWidth}px`;
      }
    };

    const getBg = () => {
      bgSrc.value = manifesto !== null ? serverImgPrefix + manifesto.background : props.src.url
    };

    const getFallback = () => {
      fallbackSrc.value = manifesto !== null ? serverImgPrefix + manifesto.fallback : props.src.url
    };

    const generateSrcset = () => {
      let srcset = [];
      if (manifesto !== null){
        if (manifesto.assets !== undefined){
          for (let size of manifesto.assets) {
            // extrapolate the size code from the filename
            let sizeNum = size.split('-').pop()
            let sizeCode = sizeNum.substring(0, sizeNum.lastIndexOf('.'))
            srcset.push(serverImgPrefix + size + ' ' + sizeCode + 'w')
          }
        }
      }
      avifSrcset.value = srcset.join(', ');
    };

    const onImageLoad = () => {
      loaded.value = true;
    };

    const styles = computed(() => {
      let properties = {
        objectPosition: props.position,
      };

      if (props.lazy) {
        properties.backgroundImage = `url(${bgSrc.value})`;
        properties.backgroundSize = 'cover';
        properties.backgroundPosition = `${props.position}`;
      }

      return properties;
    });

    onMounted(() => {
      getBg();
      generateSrcset();
      getFallback();
      getSize();
      window.addEventListener('resize', getSize, { passive: true });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', getSize, { passive: true });
    });

    return {
      size,
      img,
      loaded,
      styles,
      avifSrcset,
      fallbackSrc,
      onImageLoad,
    };
  }
};
</script>