<template>
  <nav ref="navRef" :style="navStyles" >
    <div class="container inner" >

      <router-link :to="{ name: 'home'}" class="logo" :class="{ 'is-hidden' : showLogo }">
        <inline-svg :src="agLogo"/>
      </router-link>

      <ul 
        ref="menuRef"
        @mouseenter="mouseWithin = true"
        @mouseleave="mouseWithin = false" 
        class="menu" 
        :class="{'small-screen' : isSmallScreen, 'open' : overlayOpen, 'disable-transitions' : disableTransitions, 'mouse-within' : mouseWithin }">
        <li v-for="item in menuItems" :key="item.label"
            @mouseover="item.children && allowHover && !isSmallScreen ? openSubmenu(item.ref) : null" 
            @mouseleave="item.children && allowHover && !isSmallScreen ? closeSubmenu() : null">
          <router-link v-if="!item.children" :to="{ name: item.route }" @click.native="closeOverlay(true)">{{ item.label }}</router-link>
          <div v-else class="expand-target" @click="item.children ? toggleSubmenu(item.ref) : null" :class="{disabled : activeSubmenu === item.ref && !allowToggle && !isSmallScreen, 'active': activeParent === item}">
            {{ item.label }} <inline-svg :src="menuCaret" :class="{ 'rotate': activeSubmenu === item.ref }"/>
          </div>
          <ul v-if="item.children" :ref="setSubMenuRef(item.ref)" :class="{ 'active': activeSubmenu === item.ref }" :style="submenuStyles(item.ref)">
            <li v-for="subItem in item.children" :key="subItem.name">
              <router-link :to="{ name: subItem.route }" @click.native="closeOverlay()">{{ subItem.label }}</router-link>
            </li>
          </ul>
        </li>
        <li><button-helper type="default" :to="{ name: 'enquire'}" @btnClicked="closeOverlay()">Enquire</button-helper></li>
      </ul>

      <div v-if="isSmallScreen" class="hamburger" :class="{'open' : overlayOpen}" @click="overlayOpen = !overlayOpen"></div>
     
    </div>
  </nav>
</template>

<script>
import { defineAsyncComponent, computed, ref, watch, reactive, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useApplicationStore } from '/stores/application';
import { debounce } from '/helpers.js';
import InlineSvg from '/components/helpers/InlineSvg.vue';
import agLogo from '/assets/img/arbor-green-logo.svg?raw';
import menuCaret from '/assets/img/menu-caret.svg?raw';

export default {
  props: {
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InlineSvg,
    ButtonHelper : defineAsyncComponent(() => import('/components/helpers/ButtonHelper.vue'))
  },
  setup(props) {
    const route = useRoute();
    const store = useApplicationStore();
    const navRef = ref(null);
    const menuRef = ref(null);
    const submenuRefs = reactive({});
    const overlayOpen = ref(false);
    const activeSubmenu = ref('');
    const defaultNavPadding = ref(0);
    const submenuOffset = ref(0);
    const disableTransitions = ref(false);
    const mouseWithin = ref(false);
    const menuItems = [
      {
        label: 'Properties for Sale',
        ref: 'properties-for-sale',
        children: [
          { route: 'properties-for-sale', label: 'All Properties' },
          { route: 'sections-for-sale', label: 'Sections' },
          { route: 'homes-for-sale', label: 'Homes' },
        ],
      },
      {
        label: 'Neighbourhood',
        ref: 'neighbourhood',
        children: [
          { route: 'neighbourhood', label: 'Overview' },
          { route: 'life-in-arbor-green', label: 'Life in Arbor Green' },
          { route: 'life-in-rolleston', label: 'Life in Rolleston' },
          { route: 'journal', label: 'Journal' },
        ],
      },
      {
        label: 'Resources',
        ref: 'resources',
        children: [
          { route: 'documents', label: 'Documents' },
          { route: 'faqs', label: 'Faqs' },
        ],
      },
      { 
        route: 'about', 
        label: 'About' 
      },
    ];

    // control behaviour on desktop - at least one of these needs to be true
    const allowToggle = ref(true);
    const allowHover = ref(false);

    const showLogo = computed(() => {
      if(props.hideLogo) {
        if(isSmallScreen.value) {
          if(overlayOpen.value) {
            return false;
          } else {
            return true;
          }
        }
      }
      return props.hideLogo;
    });

    const isSmallScreen = computed(() => {
      return store.breakpoint === 'xs' || store.breakpoint === 'sm' || store.breakpoint === 'md' 
    });

    const isHome = computed(() => {
      return route.name === 'home'
    });

    const navStyles = computed(() => {
      if(isSmallScreen.value) {
        return null;
      } else {
        const absoluteOffset = (12)// add manual offset
        return activeSubmenu.value ? { paddingBottom: (submenuOffset.value + defaultNavPadding.value - absoluteOffset) + 'px'  } : null;
      }
    });

    const activeParent = computed(() => {
      return menuItems.find(item =>
        item.children && item.children.some(child => child.route === route.name)
      );
    });

    const closeOverlay = (resetSubmenu) => {
      if(isSmallScreen.value) {
        if(resetSubmenu) {
          setTimeout(() => { // execute post animation
            activeSubmenu.value = null;
          }, 650);
        }
        overlayOpen.value = false;
      } else {
        closeSubmenu();
      }
    };

    const submenuStyles = (target) => {
      if(isSmallScreen && activeSubmenu.value === target ) {
        return { height: submenuOffset.value + 'px', opacity: 1 };
      } else {
        return null;
      }
    };

    const setSubMenuRef = (label) => (el) => {
      submenuRefs[label] = el;
    };

    const openSubmenu = (target) => {
      activeSubmenu.value = target;
    };

    const closeSubmenu = (source) => {
      activeSubmenu.value = null;
    };

    const toggleSubmenu = (target) => {
      if (activeSubmenu.value === target) {
        closeSubmenu();
      } else {
        openSubmenu(target);
      }
    };

    const lockBodyScroll = (value) => {
      if(value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.removeProperty('overflow');
      }
    };

    const handleOutsideClick = (event) => {
      if (!isSmallScreen.value && menuRef.value && !menuRef.value.contains(event.target)) {
        closeSubmenu();
      }
    };
    
    const handleScroll = debounce(() => {
      if (!isSmallScreen.value && activeSubmenu.value) {
        closeSubmenu();
      }
    }, 100);

    watch(activeSubmenu, (newValue) => {
      if (newValue && submenuRefs[newValue]) {
        submenuOffset.value = submenuRefs[newValue].scrollHeight;
      }
    });

    watch(isSmallScreen, (newValue) => {
      activeSubmenu.value = null;
      if(newValue) {
        disableTransitions.value = true;
        setTimeout(() => {
          disableTransitions.value = false;
        }, 500);
      }
    });

    watch(overlayOpen, (newValue) => {
      lockBodyScroll(newValue);
      if(newValue) {
        activeSubmenu.value = activeParent.value ? activeParent.value.ref : null;
      }
    });

    onMounted(() => {
      document.addEventListener('click', handleOutsideClick, { passive: true });
      window.addEventListener('scroll', handleScroll, { passive: true });
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleOutsideClick, { passive: true });
      window.removeEventListener('scroll', handleScroll, { passive: true });
    });
   

    return {
      activeSubmenu,
      submenuRefs,
      submenuOffset,
      menuItems,
      isHome,
      isSmallScreen,
      setSubMenuRef,
      openSubmenu,
      closeSubmenu,
      toggleSubmenu,
      overlayOpen,
      submenuStyles,
      navStyles,
      closeOverlay,
      navRef,
      menuRef,
      allowToggle,
      allowHover,
      activeParent,
      disableTransitions,
      agLogo,
      menuCaret,
      showLogo,
      mouseWithin
    };
  }
}
</script>


