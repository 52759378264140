<template>
  <picture>
    <source :sizes="size" :srcset="avifSrcset" type="image/avif">
    <img ref="img"
         :src="fallbackSrc"
         :alt="alt"
         :style="styles"
         :class="{ loaded: loaded}"
         :loading="lazy ? 'lazy' : 'eager'"
         @load="onImageLoad">
  </picture>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import Manifesto from '/imageManifesto.json';

export default {
  props: {
    src: {
      type: String,
      required: true
    },
    alt: String,
    position: String,
    lazy: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const size = ref(null);
    const img = ref(null);
    const loaded = ref(false);
    const bgSrc = ref('');
    const fallbackSrc = ref('');
    const avifSrcset = ref('');

    const curImage = computed(() => {
      return Manifesto[props.src] || {};
    });

    const getImageSrc = (source, format) => {
      return `/${source}.${format}`;
    };

    const getSize = () => {
      const image = img.value;
      const width = Math.floor(image.offsetWidth);
      const height = Math.floor(image.offsetHeight);
      const ratio = width / height;

      const originalWidth = curImage.value.width;
      const originalHeight = curImage.value.height;
      const imgRatio = originalWidth / originalHeight;

      const overflow = ratio > imgRatio ? 'y' : 'x';
      const proposedWidth = Math.round(height * imgRatio);

      if (overflow === 'y') {
        size.value = width > proposedWidth ? `${width}px` : `${proposedWidth}px`;
      } else {
        size.value = `${proposedWidth}px`;
      }
    };

    const getBg = () => {
      bgSrc.value = curImage.value.background;
    };

    const getFallback = () => {
      fallbackSrc.value = curImage.value.fallback;
    };

    const generateSrcset = () => {
      const assets = curImage.value.assets || [];
      const srcset = assets.map((asset) => {
        const src = getImageSrc(asset, 'avif');
        return `${src} ${asset.split('-').pop()}w`;
      });
      avifSrcset.value = srcset.join(', ');
    };

    const onImageLoad = () => {
      loaded.value = true;
    };

    const styles = computed(() => {
      let properties = {
        objectPosition: props.position,
      };

      if (props.lazy) {
        properties.backgroundImage = `url(${bgSrc.value})`;
        properties.backgroundSize = 'cover';
        properties.backgroundPosition = `${props.position}`;
      }

      return properties;
    });

    onMounted(() => {
      getBg();
      generateSrcset();
      getFallback();
      getSize();
      window.addEventListener('resize', getSize, { passive: true });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', getSize, { passive: true });
    });

    return {
      size,
      img,
      loaded,
      styles,
      avifSrcset,
      fallbackSrc,
      onImageLoad
    };
  }
};
</script>