<template>
  <div ref="svgContainer" class="svg-wrapper"></div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
  props: {
    src: {
      type: String,
      required: true
    },
    class: {
      type: [String, Object, Array],
      default: ''
    }
  },
  setup(props) {
    const svgContainer = ref(null);

    const updateSvgClass = () => {
      const svg = svgContainer.value.querySelector('svg');
      if (svg) {
        const classes = Array.isArray(props.class) ? props.class.join(' ') : props.class;
        svg.setAttribute('class', classes);
      }
    };

    const injectSvg = () => {
      if (svgContainer.value) {
        svgContainer.value.innerHTML = props.src;
        // Ensure classes are applied after the SVG is injected.
        updateSvgClass();
      }
    };

    onMounted(injectSvg);

    // Watch for changes in the src and class props to update the SVG accordingly.
    watch(() => props.src, injectSvg);
    watch(() => props.class, updateSvgClass, { deep: true });

    return {
      svgContainer
    };
  }
};
</script>
